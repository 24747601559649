import React, {useEffect, useRef, useState, useContext} from "react";
import {Camera} from "react-camera-pro";
import CardMakerToolbar from "./CardMakerToolbar";
import {v4 as uuidv4} from 'uuid';

import DraggableTextInput from "./DraggableTextInput";
import "../../css/CardMaker.css";
import TextToolbar from "./TextToolbar";
import html2canvas from "html2canvas";
import {CardBorderWidth} from "../constants";
import {snakeToCamel, withParams} from "../GlobalUtils";
import {useTranslation} from "react-i18next";
import { useDialogs } from '@toolpad/core/useDialogs';



import {useLocation, useNavigate} from 'react-router-dom';


import {Modal, ModalClose, ModalDialog} from '@mui/joy';
import {AppContext} from "../../App";
import playroom from "./playroom/Playroom";
import backendService from "../BackendService";


const CardMaker = (props) => {
    const dialogs = useDialogs();

    const [cameraEnabled, setCameraEnabled] = useState(true);
    const {t} = useTranslation();

    const location = useLocation();
    const navigate = withParams(useNavigate(), location);

    const {playroomId} = useContext(AppContext);

    const self = useRef(null);
    const canvasRef = useRef(null);
    const contentRef = useRef(null);
    const imageRef = useRef(null);

    let [state, setState] = useState("editor");

    const [image, setImage] = useState(null)
    const [imageClass, setImageClass] = useState("")
    const [textInputs, setTextInputs] = useState([])
    const [textInFocus, setTextInFocus] = useState("")

    const [scale, setScale] = useState(1);

    const [facingMode, setFacingMode] = useState("user");

    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [changeMade, setChangeMade] = useState(false);

    useEffect(() => {
        const task = async () => {
            switch(location.pathname){
                case `/${playroomId}/cardMaker/camera`:

                    if(changeMade){

                        const confirmed = await dialogs.confirm(t("discard_changes?"), {
                            okText: t("discard"),
                            cancelText: t("cancel"),
                            title: ""
                        });

                        if(!confirmed){
                            navigate(`/${playroomId}/cardMaker`);
                            return;
                        }
                    }
                    setTextInputs([]);
                    setCameraEnabled(true);
                    setChangeMade(false);
                    break;
                case `/${playroomId}/cardMaker`:
                    // TODO if no BG photo exists - redirect to /camera
                    setCameraEnabled(false);
                    break;
            }
        }
        task();

    }, [location])

    const onFacingModeChange = (facingMode) => {
        if(facingMode === "environment"){
            setImageClass("flipped");
        } else {
            setImageClass("");
        }
        setFacingMode(facingMode)
    }
    const onTextFocus = (id) => {
        console.log("onTextFocus");
        setState("text");
        setTextInFocus(id);
        setIsEditing(true);
        console.log(textInFocus)
    }
    const onTextBlur = () => {
        console.log("onTextBlur");
        setState("editor");
        setTextInFocus("");
        setIsEditing(false);
        console.log(textInFocus)
    }
    const onTextDelete = () => {
        console.log("onTextDelete");
        if(!textInFocus){
            return;
        }
        setTextInputs(textInputs => [...textInputs.filter(ti => ti.id != textInFocus)]);
        setTextInFocus("");
        setState("editor");
        setIsEditing(false);
    }
    const onTextColorChange = (color) => {
        if(!textInFocus){
            return;
        }
        console.log(color);
        textInputs.filter(ti => ti.id == textInFocus).map(ti => ti.color = color);
        setTextInputs(textInputs => [...textInputs]);
    }
    const onFontSizeChangeEnd = e => {
        if(!textInFocus){
            return;
        }
        console.log("onFontSizeChangeEnd");
        textInputs.filter(ti => ti.id === textInFocus).map(ti => ti.forceFocus = false);
        setTextInputs(textInputs => [...textInputs]);
    }
    const onFontSizeChangeStart = e => {

        if(!textInFocus){
            return;
        }
        console.log("onFontSizeChangeStart");
        console.log(textInFocus);
        textInputs.filter(ti => ti.id == textInFocus).map(ti => ti.forceFocus = true);
        setTextInputs(textInputs => [...textInputs]);
    }
    const onFontChange = (font) => {
        if(!textInFocus){
            return;
        }
        textInputs.filter(ti => ti.id == textInFocus).map(ti => ti.fontFamily = font);
        setTextInputs(textInputs => [...textInputs]);
    }
    const onFontSizeChange = (fontSize) => {
        if(!textInFocus){
            return;
        }
        textInputs.filter(ti => ti.id == textInFocus).map(ti => ti.fontSize = fontSize);
        setTextInputs(textInputs => [...textInputs]);
    }

    const onCreateText = () => {
        console.log("Create Text");
        setChangeMade(true);

        setTextInputs(textInputs => [...textInputs, {
            id: uuidv4(),
            text: t("text_field_placeholder"),
            position: {x: 0, y: 100},
            fontSize: 20,
            direction: "ltr",
            fontFamily: "Rubik",
            color: "#FFF",
            forceFocus: false,
            created: true
        }])
        setTimeout(
            () => setTextInputs(textInputs => [...textInputs.map(ti => {delete ti.created; return ti})]),
            1
        );

    }
    const onCapture = async (imageData, sendImmediately = false) => {
        navigate(`/${playroomId}/cardMaker`);
        setImage(imageData);
        setCameraEnabled(false);
        if(sendImmediately){
            await saveCard(imageData, true)
        }

    }
    const showCamera = () => {
        navigate(`/${playroomId}/cardMaker/camera`);
        setCameraEnabled(true);
    }

    const updateTextPos = (id, position) => {
        textInputs.filter(ti => ti.id == id).map(ti => ti.position = position)
    }
    const updateText = (id, text) => {
        textInputs.filter(ti => ti.id == id).map(ti => {
            ti.text = text
            ti.direction = (text.length && text.charCodeAt(0) >= 0x590 && text.charCodeAt(0) <= 0x5FF) ? "rtl" : "ltr";
            return ti;
        })
    }
    const getStateToolbar = () => {
        switch(state){
            case "editor":
                return <CardMakerToolbar onCreateText={onCreateText}
                                         onShowCamera={showCamera}
                    />
            case "text":
                return <TextToolbar onFontSizeChange={onFontSizeChange}
                                    onTextDelete={onTextDelete}
                                    onColorChange={onTextColorChange}
                                    onFontChange={onFontChange}
                                    onFontSizeChangeStart={onFontSizeChangeStart}
                                    onFontSizeChangeEnd={onFontSizeChangeEnd}
                                    fontSize={textInputs.filter(ti => ti.id == textInFocus)[0].fontSize}
                                    fontFamily={textInputs.filter(ti => ti.id == textInFocus)[0].fontFamily}
                                    color={textInputs.filter(ti => ti.id == textInFocus)[0].color}
                    />
            default:
                return null;

        }
    }

    const onClose = async e => {
        if(changeMade){
            const confirmed = await dialogs.confirm(t("discard_changes?"), {
                okText: t("discard"),
                cancelText: t("cancel"),
                title: ""
            });
            if(!confirmed){
                return;
            }
        }
        if(cameraEnabled) {
            setCameraEnabled(false);
            if(props.onClose){
                props.onClose(null);
            }

        } else {
            //navigate(`/${playroomId}/cardMaker/camera`)
            window.history.back();
            setTextInputs([]);
            setChangeMade(false);
        }



    }

    const saveCard = async (imageData = null, skipRendering = false) => {
        setIsLoading(true);
        let startTime = new Date().getTime()
        console.log("SAVE CARD: " + startTime)

        let tempCanvas = null;
        if(!skipRendering){
            console.log("rendering text...");
            tempCanvas = await new Promise((res, rej) => {
                contentRef.current.style.transform = `scale(${1080 / (self.current.offsetWidth * window.devicePixelRatio)})`;
                contentRef.current.style.overflow = `visible`;
                imageRef.current.style.opacity = "0"
                html2canvas(contentRef.current, {
                    backgroundColor: null, onclone: clone => {
                        Array.from(clone.querySelectorAll("textarea")).forEach((textArea) => {
                            if (textArea && textArea.value) {
                                textArea.style.wordWrap = "normal";
                                textArea.style.letterSpacing = "normal";

                                const div = clone.createElement("div")
                                div.innerText = textArea.value
                                let cs = getComputedStyle(textArea);

                                Object.values(cs).map(p => {
                                    return {key: p, value: cs.getPropertyValue(p)}
                                }).filter(v => v.value != "").map(v => {
                                    div.style[snakeToCamel(v.key)] = v.value
                                });
                                textArea.style.display = "none"
                                textArea.parentElement.append(div);
                            }
                        });


                    }
                }).then(cvs => {
                    self.current.style.width = `auto`;
                    self.current.style.height = "100vh";
                    self.current.style.maxWidth = "100vw";
                    imageRef.current.removeAttribute("style");
                    contentRef.current.removeAttribute("style");

                    res(cvs);

                });
            })

            console.log("text rendered");
        }

        let cvs = document.createElement("canvas");
        cvs.width = 1080;
        cvs.height = 1560;
        cvs.style.width = "1080px"
        cvs.style.height = "1560px"
        let ctx = cvs.getContext("2d");

        console.log("rendering main image...");
        const newImage = new Image();
        await new Promise((res, rej) => {
            newImage.onload = () => {
                res()
            };
            newImage.src = imageData || imageRef.current.src;



        });
        const imgRatio = newImage.width / newImage.height;
        const canvasRatio = 1080 / 1560;

        let drawWidth, drawHeight, offsetX, offsetY;

        if (imgRatio > canvasRatio) {
            console.log("is wider");
            // התמונה רחבה יותר מה-canvas
            drawHeight = newImage.height;
            drawWidth = drawHeight * canvasRatio;
            offsetX = (newImage.width - drawWidth) / 2;
            offsetY = 0;
        } else {
            // התמונה גבוהה יותר מה-canvas
            console.log("is narrower");
            drawWidth = newImage.width;
            drawHeight = drawWidth / canvasRatio;
            offsetX = 0;
            offsetY = (newImage.height - drawHeight) / 2;
        }
        console.log(facingMode);
        if(facingMode === "user"){
            ctx.save();
            ctx.translate(cvs.width, 0);
            ctx.scale(-1, 1);
            ctx.drawImage(newImage, offsetX, offsetY, drawWidth, drawHeight, cvs.width, 0, -cvs.width, cvs.height);
            ctx.restore()
        } else {
            ctx.drawImage(newImage, offsetX, offsetY, drawWidth, drawHeight, 0, 0, cvs.width, cvs.height);
        }
        console.log("main image rendered")

        if(!skipRendering){

            tempCanvas.style.width = "1080px";
            tempCanvas.style.height = "1560px";
            ctx.drawImage(tempCanvas, 0, 0)
        }


        // add frame
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, cvs.width, CardBorderWidth);
        ctx.fillRect(0, cvs.height - CardBorderWidth, cvs.width, CardBorderWidth);
        ctx.fillRect(0, 0, CardBorderWidth, cvs.height);
        ctx.fillRect(cvs.width - CardBorderWidth, 0, CardBorderWidth, cvs.height);

        let canvasURL = cvs.toDataURL("image/jpeg", 0.8);
        let blob = await new Promise((res, rej) => {
            cvs.toBlob(res, "image/jpeg");
        })
        console.log("SAVE CARD DONE: " + (new Date().getTime() - startTime))
        setIsLoading(false);
        if(props.onCardCreated){
            props.onCardCreated(canvasURL, blob);
        }
        //console.log(canvasURL);
    }

    return (
        <div className={"card_maker"} ref={self}>
            {cameraEnabled ? <CameraComponent allowMultiple={props.allowMultiple} onCapture={onCapture} onFacingModeChange={onFacingModeChange}/> :
                <>
                    {getStateToolbar()}
                    <div id={"content"} ref={contentRef} >
                        {textInputs.map(ti => {
                            return <DraggableTextInput
                                id={ti.id}
                                key={ti.id}
                                text={ti.text}
                                position={ti.position}
                                fontSize={ti.fontSize}
                                fontFamily={ti.fontFamily}
                                direction={ti.direction}
                                color={ti.color}
                                forceFocus={ti.forceFocus}
                                created={ti.created}
                                onChange={updateText}
                                onDrag={updateTextPos}
                                onFocus={onTextFocus}
                                onBlur={onTextBlur}
                                onDelete={onTextDelete}
                                />
                        })}
                        {
                            (image && !cameraEnabled) ? <img ref={imageRef}
                                                          className={imageClass}
                                                          src={image}
                                                             style={{opacity: state == "editor" ? 1 : 0.75}}
                                                    /> : null
                        }
                    </div>
                    {state === "editor" ?
                        <div className={"bottom_bar"}>
                            <div className="send_button" onClick={e => saveCard()}></div>
                        </div>
                        : null
                    }
                </>
            }
            {isLoading ? <div id={"render_preloader"} className={"dir"}>{t('card_maker_loading')}</div> : null}
            {isEditing || <div className="editor_button" id="close" onClick={onClose}>✕</div>}

        </div>
    );
};

const CameraComponent = (props) => {
    const dialogs = useDialogs();

    const camera = useRef(null);
    const [image, setImage] = useState(null);
    const [facingMode, setFacingMode] = useState("user");
    const [cameraAvailable, setCameraAvailable] = useState(true);
    const [cameraPopupVisible, setCameraPopupVisible] = useState(false);
    const [stream, setStream] = useState(null);
    const [permissionsChecked, setPermissionsChecked] = useState(false);
    const {t} = useTranslation();

    const file = useRef(null);

    useEffect(() => {
        if(image){
            props.onCapture?.(image)
        }
    }, [image])
    useEffect(() => {
        props.onFacingModeChange?.(facingMode);
    }, [facingMode])

    const showCameraGuide = () => {
        setCameraPopupVisible(true);
    }


    useEffect(() => {
        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({name: 'camera'}).then(permissionStatus => {
                setPermissionsChecked(true);
                if (permissionStatus.state === 'granted') {
                    // GREAT!
                } else {
                    setCameraAvailable(false);
                }

                permissionStatus.onchange = () => {
                    if (permissionStatus.state === 'granted') {
                        window.location.reload(); // רענון העמוד כדי לאתחל את החיבור למצלמה
                    }
                };
            });
        } else {
            console.warn('Permissions API not supported, using fallback.');

            // בדוק אם המצלמה זמינה בצורה אחרת, למשל דרך גישה ישירה ל-Media API
            navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    setPermissionsChecked(true);
                    setCameraAvailable(true);
                    // המשך בעבודה עם הזרם
                })
                .catch(() => {
                    setPermissionsChecked(true);
                    setCameraAvailable(false);
                });
        }


        return () => {
            if (stream) {
                console.log(stream.getTracks())
                stream.getTracks().forEach(track => track.stop());
            }
        };

    }, []);

    let readFile = async (e) => {
        console.log(e);
        console.log(file.current.value);
        if (FileReader) {
            if(file?.current?.files?.length > 1){
                const confirmed = await dialogs.confirm(t("confirm_multiple_cards"), {
                    okText: t("confirm"),
                    cancelText: t("cancel"),
                    title: ""
                });

                if(!confirmed){
                    return;
                }
            }

            [...file.current.files].map(f => {
                let fr = new FileReader();
                fr.onload = function () {
                    if(file.current.files.length == 1){
                        setImage(fr.result);
                        setFacingMode("environment");
                    } else {
                        props.onCapture?.(fr.result, true)
                    }

                }
                fr.readAsDataURL(f);
            })
        }
    }
    let openFile = () => {
        file.current.click();
    }

    let flip = () => {
        let mode = camera.current.switchCamera();
        setFacingMode(mode);
    }
    let cameraError = {};
    return (
        <div className={"camera_component"}>
            <Camera ref={camera} facingMode={facingMode} aspectRatio={"cover"} errorMessages={cameraError}/>
            <div className={"bottom_bar"} >
                <div className={"image_button capture_button"} style={{pointerEvents: cameraAvailable ? "auto" : "none"}} onClick={() => setImage(camera.current.takePhoto())}></div>
                <div className={"image_button flip_button"} style={{pointerEvents: cameraAvailable ? "auto" : "none"}} onClick={flip}></div>
                <div className={"image_button gallery_button"} onClick={openFile}></div>
            </div>

            <input type="file" id="fileInput" ref={file} onChange={(e) => {readFile(e)}} accept="image/png,image/jpeg" multiple={props.allowMultiple} />

            {
                cameraAvailable ||
                <div className="camera_warning dir">
                    <h3>{t("wanna_take_photos")}</h3>
                    <button className="text_button default" onClick={showCameraGuide}>{t("allow_camera")}</button>
                </div>
            }
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={cameraPopupVisible}
                onClose={() => setCameraPopupVisible(false)}
                className="dir"
            >
                <ModalDialog>
                    <ModalClose />
                    <h3 id="modal-title">{t("camera_guide_title")}</h3>
                    {
                        t("camera_guide_text").split("\n").map(line =>
                            <div>{line}</div>
                        )
                    }
                </ModalDialog>


            </Modal>


        </div>
    );
}

export default React.memo(CardMaker);