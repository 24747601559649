import EventEmitter from "events";
import io from 'socket.io-client';
import {
    generateFirestoreId,
    objectToArray,
    clone,
    postApi,
    getApi,
    removeDuplicates,
    createSequentialArray
} from "./GlobalUtils";
import {DEFAULT_PLACEMENT_CONFIG, SELECTION_PANEL_PLACEMENTS} from "./components/playroom/PlayroomConstants";

import i18n from "./i18n";


let cards = {};
let cardCreationActivity = {};
let parties = {}

let sessionActivity = {}
let enrichedSessionActivity = {};
let missingCardIds = {};

let sessionConfig = {}


const DISPLAY_INACTIVE_PARTIES = true;
const SOCKET_SERVER_URL = process.env.REACT_APP_SERVER_URL || "https://socket.vicapro.com";
//const SOCKET_SERVER_URL = "https://socket.vicapro.com";

const backfillDefaults = (config) => {
    console.log(JSON.stringify(config));

    if(!config){
        config = {};
    }

    DEFAULT_PLACEMENT_CONFIG.label = i18n.t("drag_here");

    config.placements ||= createSequentialArray(SELECTION_PANEL_PLACEMENTS).map(i => DEFAULT_PLACEMENT_CONFIG);
    config.arrangement ||= "paging";
    config.partyPermissions ||= {};
    if(config.partyPermissions.addCards === undefined){
        config.partyPermissions.addCards = true;
    }


    return config;
}
const updateCardCreationActivity = (card) => {
    if(card.type !== "user") return;
    if(!cardCreationActivity[card.sender]){
        cardCreationActivity[card.sender] = {
            sender: card.sender,
            senderFullName: card.senderFullName,
            creations: {}
        }
    }
    cardCreationActivity[card.sender].creations[card.id] = card;
}
/**
 * This function cleans up duplicate selections of a single card by a party. This can happen due to disconnection of party, and making
 * selections in the meantime. When the party reconnects - strange things happen.
 * @param activity
 */
const verifyActivity = (activity) => {
    Object.values(activity).map(partyActivity => {
        let sel = partyActivity.selections;
        if(sel){
            let sa = objectToArray(sel, sessionConfig.placements.length || SELECTION_PANEL_PLACEMENTS)
            sa = removeDuplicates(sa);

            partyActivity.selections = sa;
        }

    });
    return activity;
}
/**
 * assembles a query-ready activity object with full card data and party data
 * @param activity raw slim activity object
 * @returns {any}
 */

const enrichActivity = (activity) => {
    let act = clone(activity);
    Object.values(act).map(partyActivity => {
        let sel = partyActivity.selections;
        if(sel){
            partyActivity.selections = sel.map(cIds => {
                if(!cIds || !cIds.length){
                    return null;
                }

                let placementCards = cIds.map(cId => {
                    if(!cards[cId]){
                        missingCardIds[cId] = true;
                    }
                    return cards[cId] || null
                })
                if(!placementCards.filter(c => c != null).length){
                    placementCards = null;
                }
                return placementCards;
            })
        }
        partyActivity.fullName = parties[partyActivity.id] ? parties[partyActivity.id].fullName : "";
    })

    if(DISPLAY_INACTIVE_PARTIES){
        Object.values(parties).map(party => {
            if(!act[party.id]){
                act[party.id] = {
                    id: party.id,
                    fullName: party.fullName,
                    selections: new Array(sessionConfig.placements.length || SELECTION_PANEL_PLACEMENTS).fill(null)
                }
            }
        })
    }

    Object.values(cards).filter(card => card.type == "user").map(card => {
        let party = act[card.sender];
        if (!party.cards) {
            party.cards = [];
        }
        party.cards.push(card);
    })


    return act;
}
class BackendService extends EventEmitter {
    #playroomId = "";
    #partyId = "";
    #token = "";
    #offline = false;
    #connected = false;
    #messageQueue = [];
    #safeEmit = function safeEmit(event, data) {
        if (this.socket.connected) {
            this.socket.emit(event, data);
        } else {
            this.#messageQueue.push({ event, data });
        }
    }

    #enrichCard = card => {
        switch(card.type){
            case "user":
                card.url = `https://content.vicapro.com/playrooms/${this.#playroomId}/cards/${card.id}.jpg`;
                card.previewUrl = card.url;
                if(card.sender){
                    card.senderFullName = parties[card.sender] ? parties[card.sender].fullName : "";
                }
                break;
            case "gallery":
                card.url = `https://content.vicapro.com/decks/${card.deckId}/web/${card.cardId}.jpg`;
                card.previewUrl = `https://content.vicapro.com/decks/${card.deckId}/preview/${card.cardId}.jpg`;
                break;
        }
        return card;
    }


    constructor() {
        super();
    }
    init(playroomId, partyId = "", token = "", fullName = "", offline = false, lang = "he"){
        console.log("INIT")
        // eslint-disable-next-line no-undef

        this.#playroomId = playroomId;
        this.#partyId = partyId;
        this.#token = token;
        this.#offline = offline;
        this.#connected = false;

        if(this.#offline){
            // TODO allow only admins
            return;
        }

        this.socket = io(`${SOCKET_SERVER_URL}`, {
            query: {playroomId, partyId, token, fullName, lang}
        });
        
        this.models = {}; // מקום לשמור את המודלים מהשרת

        this.socket.on('connect', () => {
            console.log('Connected to the server');
            this.#connected = true;
            while (this.#messageQueue.length > 0) {
                const { event, data } = this.#messageQueue.shift();
                this.socket.emit(event, data);
            }
            this.emit('connected');
        });
        this.socket.on('disconnect', (reason) => {
            console.log('Disconnected from the server');
            console.log(reason)
            this.#connected = false;
            this.emit('disconnected');
        });

        this.socket.on('card_added', data => {
            if(data.status === "deleted") return;

            let enrichedCard = this.#enrichCard(data);
            updateCardCreationActivity(enrichedCard);

            if(!cards[enrichedCard.id]){
                cards[enrichedCard.id] = enrichedCard;
            }
            this.emit('card_added', enrichedCard);

            if(missingCardIds[enrichedCard.id]){
                enrichedSessionActivity = enrichActivity(sessionActivity);
                this.emit("activity", enrichedSessionActivity);
                delete missingCardIds[enrichedCard.id];
            }
        });

        this.socket.on('card_deleted', cardId => {
            this.emit('card_deleted', cardId);
            let card = cards[cardId];
            if(card){
                if(cardCreationActivity[card.sender]){
                    delete cardCreationActivity[card.sender].creations[cardId]
                }
                delete cards[cardId];
            }



        });

        this.socket.on("playroom_state", playroomData => {
            console.log(playroomData);
            // CONFIG
            if(playroomData.isOwner){
                this.#partyId = "owner";
            }

            console.log("PLAYROOM_STATE");
            console.log(playroomData);

            // PARTIES
            parties = playroomData.parties;

            // CARDS
            if(playroomData.currentSession.cards){
                Object.keys(playroomData.currentSession.cards).map(cId => {
                    let card = playroomData.currentSession.cards[cId];
                    if(card.status === "deleted"){
                        delete playroomData.currentSession.cards[cId];
                    }
                })

                Object.values(playroomData.currentSession.cards).filter(card => card.status !== "deleted").map(card => {
                    this.#enrichCard(card);
                    updateCardCreationActivity(card);
                })
            }
            Object.assign(cards, playroomData.currentSession.cards)

            // ACTIVITY
            if(!playroomData.currentSession){
                playroomData.currentSession = {};
            }
            if(!playroomData.currentSession.activity){
                playroomData.currentSession.activity = {};
            }

            sessionActivity = playroomData.currentSession.activity;
            sessionConfig = backfillDefaults(playroomData.currentSession.config)

            Object.values(sessionActivity).map(v => {v.selections = objectToArray(v.selections || {}, sessionConfig.placements.length || SELECTION_PANEL_PLACEMENTS)}) // TODO get from session settings
            verifyActivity(sessionActivity);
            enrichedSessionActivity = enrichActivity(sessionActivity);
            playroomData.currentSession.enrichedActivity = enrichedSessionActivity;

            this.emit("playroom_state", playroomData);
        })

        this.socket.on('activity', data => {
            //console.log("ACTIVITY MESSAGE")
            sessionActivity[data.id] = data;
            sessionActivity[data.id].selections = removeDuplicates(objectToArray(data.selections || {}, sessionConfig.placements.length || SELECTION_PANEL_PLACEMENTS));
            enrichedSessionActivity = enrichActivity(sessionActivity);

            this.emit("activity", enrichedSessionActivity)
        })
        this.socket.on('party_joined', data => {
            console.log("PARTY JOINED")
            parties[data.id] = data;
            enrichedSessionActivity = enrichActivity(sessionActivity);

            this.emit("party_joined", data)
        })

        this.socket.on('session_updated', data => {
            if(data.resetSession){
                sessionActivity = {};
            }

            // TODO handle cards while not reuseDeck...
            sessionConfig = backfillDefaults(data);
            this.emit("session_updated", sessionConfig);
        })

        this.socket.on("message", data => {
            console.log(data);
            this.emit("message", data);
        })

        this.socket.on("go_offline", () => {
            console.log("going offline");
            this.emit("go_offline");    // TODO handle socket disconnection
        })
    }


    addCard(cardData, cardMetadata) {
        let id = generateFirestoreId();

        if(!this.#offline && this.#playroomId && this.#partyId){
            this.#safeEmit("add_card", {
                data: cardData,
                metadata: cardMetadata,
                id: id
            })
        }

        return id;
    }
    deleteCard(cardId) {
        if(!this.#offline && this.#playroomId && this.#partyId == "owner"){


            let card = cards[cardId];
            if(card){
                if(cardCreationActivity[card.sender]){
                    delete cardCreationActivity[card.sender].creations[cardId]
                }
                delete cards[cardId];
            }

            Object.values(sessionActivity).map(sa => {
                if(sa.selections){
                    sa.selections = sa.selections.map(s => {
                        if(!s) return null;
                        s.filter(cId => cards[cId])
                        return s;
                    });
                }
            })
            enrichedSessionActivity = enrichActivity(sessionActivity)
            this.emit('activity', enrichedSessionActivity);
            this.emit('card_deleted', cardId);

            this.#safeEmit("delete_card", {
                id: cardId,
            })
        }
    }

    newSession(config){
        if(!this.#offline && this.#playroomId && this.#partyId){
            this.#safeEmit("new_session", config)
        }
    }
    updateSession(config){
        if(!this.#offline && this.#playroomId && this.#partyId){
            this.#safeEmit("update_session", config)
        }
    }

    updateActivity(activityData) {
        if(this.#offline || !this.#playroomId || !this.#partyId){
            return false;
        }
        sessionActivity[this.#partyId] = activityData;
        this.#safeEmit("activity", activityData)
    }

    getCards(){
        return cards;
    }
    getParties(){
        return parties;
    }
    getCardCreationActivity(){
        return cardCreationActivity;
    }
    getActivity(){
        return enrichedSessionActivity;
    }
    getPlayroomId(){
        return this.#playroomId;
    }
    getPartyId(){
        return this.#partyId;
    }
    setOffline(offline) {
        this.#offline = offline;
    }
    async createPlayroom(config = null){
        let res = await postApi("playrooms", config)
        return res.playroomId;
    }
    async getDeck(deckId){
        let res = await getApi(`decks/${deckId}`)
        return res.deck;
    }
    getCard(cardId){
        return cards[cardId];
    }
    getSessionConfig(){
        return sessionConfig;
    }

}

const backendService = new BackendService();
export default backendService;